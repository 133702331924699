<template>
  <div>
    <div class="columns">
      <div class="column is-narrow">
        <b-field label="Group by">
          <b-radio-button
            v-model="localValue.groupBy"
            native-value="month"
          >
            Month
          </b-radio-button>
          <b-radio-button
            v-model="localValue.groupBy"
            native-value="quarter"
          >
            Quarter
          </b-radio-button>
          <b-radio-button
            v-model="localValue.groupBy"
            native-value="year"
          >
            Year
          </b-radio-button>
          <b-radio-button
            v-model="localValue.groupBy"
            native-value="fiscalYear"
          >
            Fiscal year
          </b-radio-button>
          <b-radio-button
            v-model="localValue.groupBy"
            native-value="all"
          >
            All
          </b-radio-button>
        </b-field>
      </div>
      <div
        v-if="localValue.groupBy !== 'all'"
        class="column"
      >
        <b-field label="Period">
          <MonthSelector
            v-if="localValue.groupBy === 'month'"
            :value="startDate.format('YYYY-MM')"
            @input="updateMonth"
          />
          <QuarterSelector
            v-else-if="localValue.groupBy === 'quarter'"
            :value="startDate.format('YYYY-Q')"
            @input="updateQuarter"
          />
          <YearSelector
            v-else-if="localValue.groupBy === 'year'"
            :value="startDate.format('YYYY')"
            @input="updateYear"
          />
          <YearSelector
            v-else-if="localValue.groupBy === 'fiscalYear'"
            :value="startDate.format('YYYY')"
            @input="updateFiscalYear"
          />
        </b-field>
      </div>
    </div>
  </div>
</template>

<script>
import moment from 'moment'

import MonthSelector from '@/components/MonthSelector.vue'
import QuarterSelector from '@/components/QuarterSelector.vue'
import YearSelector from '@/components/YearSelector.vue'

export default {
  components: {
    MonthSelector,
    QuarterSelector,
    YearSelector,
  },
  props: {
    value: {
      type: Object,
      required: true,
    },
  },
  data () {
    return {
      localValue: JSON.parse(JSON.stringify(this.value)),
    }
  },
  computed: {
    startDate () {
      return moment(this.localValue.startDate)
    },
  },
  watch: {
    localValue: {
      deep: true,
      handler () {
        this.$emit('input', JSON.parse(JSON.stringify(this.localValue)))
      },
    },
    'localValue.groupBy' (v) {
      let startDate = moment(this.localValue.startDate)
      if (!startDate.isValid()) {
        startDate = moment()
      }
      startDate.daysInMonth(1)
      let endDate = moment()
      if (v === 'month')
        endDate = startDate.clone()
          .add(1, 'month').subtract(1, 'day')
      else if (v === 'quarter') {
        startDate.month(this.quarterMonth(startDate.month()))
        endDate = startDate.clone()
          .add(3, 'month').subtract(1, 'day')
      } else if (v === 'year') {
        startDate.month(0)
        endDate = startDate.clone()
          .add(1, 'year').subtract(1, 'day')
      } else if (v === 'fiscalYear') {
        startDate.month(5)
        endDate = startDate.clone()
          .add(1, 'year').subtract(1, 'day')
      }
      else {
        this.localValue.startDate = null
        this.localValue.endDate = null
        return
      }

      this.localValue.startDate = startDate.format('YYYY-MM-DD')
      if (endDate.isValid())
        this.localValue.endDate = endDate.format('YYYY-MM-DD')
    },
  },
  methods: {
    quarterMonth (m) {
      return Math.floor(m / 3) * 3
    },
    updateMonth (v) {
      const startDate = moment(v, 'YYYY-MM')
      this.localValue.startDate = startDate.format('YYYY-MM-DD')
      this.localValue.endDate = startDate
        .add(1, 'month').subtract(1, 'day')
        .format('YYYY-MM-DD')
    },
    updateQuarter (v) {
      const startDate = moment(v, 'YYYY-Q')
      this.localValue.startDate = startDate.format('YYYY-MM-DD')
      this.localValue.endDate = startDate
        .add(3, 'month').subtract(1, 'day')
        .format('YYYY-MM-DD')
    },
    updateYear (v) {
      const startDate = moment(v, 'YYYY')
      this.localValue.startDate = startDate.month(0).format('YYYY-MM-DD')
      this.localValue.endDate = startDate
        .add(1, 'year').subtract(1, 'day')
        .format('YYYY-MM-DD')
    },
    updateFiscalYear (v) {
      const startDate = moment(v, 'YYYY')
      this.localValue.startDate = startDate.month(5).format('YYYY-MM-DD')
      this.localValue.endDate = startDate
        .add(1, 'year').subtract(1, 'day')
        .format('YYYY-MM-DD')
    },
  },
}
</script>

<style lang="scss" scoped>
</style>
