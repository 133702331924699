<template>
  <div class="container">
    <b-button
      icon-left="plus"
      type="is-primary"
      class="is-pulled-right"
      rounded
      outlined
      @click="addItem"
    >
      Add
    </b-button>

    <h1 class="is-size-3 mb-3">
      Designers
    </h1>

    <b-message v-if="items && items.length === 0">
      Nothing yet
    </b-message>
    <b-table
      v-else
      :data="items"
      striped
      hoverable
    >
      <b-table-column
        v-slot="props"
        width="10"
      >
        <b-button
          size="is-small"
          icon-left="dollar-sign"
          type="is-success"
          outlined
          @click="goToFees(props.row.id)"
        >
          Fees
        </b-button>
      </b-table-column>
      <b-table-column
        v-slot="props"
        width="10"
      >
        <b-tag>
          {{ props.row.fees.length }}
        </b-tag>
      </b-table-column>
      <b-table-column
        v-slot="props"
        field="name"
      >
        <a @click="editItem(props.row.id)">
          {{ props.row.name }}
        </a>
      </b-table-column>
      <b-table-column
        v-slot="props"
        width="10"
      >
        <div class="buttons">
          <b-button
            size="is-small"
            icon-left="trash"
            type="is-danger"
            outlined
            @click="removeItem(props.row.id)"
          />
        </div>
      </b-table-column>
    </b-table>
  </div>
</template>

<script>
import ViewMixin from './_mixin'

import ModalForm from '@/components/Modal/Designer.vue'

export default {
  mixins: [ViewMixin({
    query: `designers {
      id name
      fees { id amount label startDate endDate }
      invoices { id invoiceNumber invoiceDate amount paymentDate }
    }`,
    modalComponent: ModalForm,
    deleteMutationName: 'designerDelete',
  })],
  methods: {
    goToFees (id) {
      this.$router.push({
        path: '/reports/DesignFees',
        query: { designerId: id },
      })
    },
  },
}
</script>

<style lang="scss" scoped>
</style>
