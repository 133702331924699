import { render, staticRenderFns } from "./PnlModal.vue?vue&type=template&id=2de8bbaa&scoped=true&"
import script from "./PnlModal.vue?vue&type=script&lang=js&"
export * from "./PnlModal.vue?vue&type=script&lang=js&"
import style0 from "./PnlModal.vue?vue&type=style&index=0&id=2de8bbaa&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "2de8bbaa",
  null
  
)

export default component.exports