<template>
  <b-table
    :data="items"
    striped
    @click="handleClick"
  >
    <b-table-column
      v-slot="props"
      :width="10"
    >
      <span class="is-size-7 greyed">
        #{{ props.row.id }}
      </span>
    </b-table-column>
    <b-table-column
      v-slot="props"
      label="Date"
      field="date"
      width="120"
      sortable
    >
      {{ props.row.date }}
    </b-table-column>
    <b-table-column
      v-slot="props"
      label="Amount"
      field="amount"
      width="100"
      numeric
      sortable
    >
      {{ props.row.amountUsd | $ }}
    </b-table-column>
    <b-table-column
      v-slot="props"
      label="Description"
    >
      {{ labelDetails(props.row) }}
    </b-table-column>
  </b-table>
</template>

<script>
import TxModal from '@/components/Accounting/TxModal.vue'

export default {
  props: {
    items: {
      type: Array,
      required: true,
    },
  },
  methods: {
    labelDetails (item) {
      let label = []
      if (item.productFamilyId) {
        const family = this.$store.getters.productFamiliesById[item.productFamilyId]
        if (family) label.push(family.name)
      }
      if (item.productId) {
        const product = this.$store.getters.productsById[item.productId]
        if (product) label.push(product.name)
      }
      if (item.category) label.push(item.category)
      if (item.description) label.push(item.description)
      return label.join(' - ')
    },
    handleClick (item) {
      this.$buefy.modal.open({
        parent: this,
        component: TxModal,
        props: { item },
      })
    },
  },
}
</script>

<style lang="scss" scoped>
.greyed {
  opacity: .5;
}
</style>
